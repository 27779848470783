import {
    ModalGraphWrapper, ModalGraph, ModalGraphDateTitle, ModalGraphOuterContainer, ModalGraphContainer, ModalGraphInnerContainer, ModalGraphTitleWrapper, ModalTarget, ModalGraphFrame, ModalSelector, ModalSelectorButton, ModalFooterSpacer
} from "./styledComponents"
import { NavBackBtnImage, NavForwardBtnImage } from "../../../style/image"
import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, LineElement, Legend, CategoryScale, LinearScale, PointElement, LineController, DoughnutController, Title } from 'chart.js'
import { Doughnut, Line } from "react-chartjs-2";
import { useDispatch } from 'react-redux'
import { useEvaluationDataState } from '../../../reducer/evaluationData/selectors'
import { useSensorGraphDataState } from '../../../reducer/sensorGraphData/selectors'
import { SensorGraphData, EvaluationData } from '../../../states/utilState'
import { fetchEvaluationData } from '../../../reducer/evaluationData/asyncAction'
import { fetchSensorGraphData } from '../../../reducer/sensorGraphData/asyncAction'
import { fetchAuthData } from "../../../reducer/authData/asyncAction"
import store from "../../../stores"

export const evaluationDispData = {
    labels: ['良好', '普通', '要改善'],
    datasets: [{
        label: '%',
        data: [0, 0, 0],
        backgroundColor: [
            'rgba(32, 178, 170, 0.7)',
            'rgba(255, 215, 0, 0.7)',
            'rgba(255, 0, 0, 0.7)',
        ],
        borderColor: [
            'rgba(32, 178, 170, 1)',
            'rgba(255, 215, 0, 1)',
            'rgba(255, 0, 0, 1)',
        ],
        borderWidth: 1
    }]
}

export const evaluationDispOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            position: 'right',
            align: 'center',
        }
    },
}

export const detailDummyDataList = [
    {
        labels: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00',
                 '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        datasets: [{
            label: '温度',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            spanGaps: true,
            borderColor: 'rgba(32, 178, 170, 1)',
            backgroundColor: 'rgba(32, 178, 170, 0.5)',
            tension: 0.3,
        }]
    },
    {
        labels: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00',
                 '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        datasets: [{
            label: '湿度',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            spanGaps: true,
            borderColor: 'rgba(135, 206, 235, 1)',
            backgroundColor: 'rgba(135, 206, 235, 0.5)',
            tension: 0.3,
        }]
    },
    {
        labels: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00',
                 '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        datasets: [{
            label: 'CO₂濃度',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            spanGaps: true,
            borderColor: 'rgba(50, 205, 50, 1)',
            backgroundColor: 'rgba(50, 205, 50, 0.5)',
            tension: 0.3,
        }]
    },
    {
        labels: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00',
                 '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        datasets: [{
            label: '音量',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            spanGaps: true,
            borderColor: 'rgba(255, 165, 0, 1)',
            backgroundColor: 'rgba(255, 165, 0, 0.5)',
            tension: 0.3,
        }]
    },
    {
        labels: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00',
                 '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        datasets: [{
            label: '照度',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            spanGaps: true,
            borderColor: 'rgba(255, 215, 0, 1)',
            backgroundColor: 'rgba(255, 215, 0, 0.5)',
            tension: 0.3,
        }]
    },
];

export let detailDummyData = detailDummyDataList[0];

export const tempDummyOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        }
    }
}

const ModalGraphContent:React.FC<{dateData: string, sensors: string[], open: Boolean}> = (props) => {
    const curDate = new Date(props.dateData);
    const [date, setDate] = useState(curDate);
    const [counter, setCount] = useState(0);    // eslint-disable-line
    const [evalCounter, setEvalCount] = useState(1000000);
    const [detailCounter, setDetailCount] = useState(2000000);
    const [dateString, setDateString] = useState(props.dateData);   // eslint-disable-line
    const [currentSensor, setSensor] = useState(0);
    const [currentGraph, setGraph] = useState(0);
    const [isOpen, setIsOpen] = useState(props.open);   // eslint-disable-line

    const evaluationDataState = useEvaluationDataState().evaluationData
    const sensorGraphDataState = useSensorGraphDataState().sensorGraphData.sensorGraph
    /* eslint-disable */
    const [evaluationData, setEvaluationData] = useState<EvaluationData[]>()    // eslint-disable-line
    const [sensorGraphData, setSensorGraphData] = useState<SensorGraphData>()    // eslint-disable-line
    const [sensorTitle, setSensorTitle] = useState(props.sensors[0])
    const [graphTitle, setGraphTitle] = useState("温度")
    /* eslint-enable */

    let newDate = date;

    /* eslint-disable */
    const countUp = () => {
        setCount(counter + 1);
    }
    /* eslint-enable */

    const evalCountUp = () => {
        setEvalCount(evalCounter + 1);
    }

    const detailCountUp = () => {
        setDetailCount(detailCounter + 1);
    }

    const prevDate = () => {
        newDate = date;
        newDate.setDate(newDate.getDate() - 1);
        setDate(newDate);
        const str: string = newDate.getFullYear() + "-" + ('0' + (newDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (newDate.getDate())).slice(-2)
        setDateString(str);
        displayNextDayButton(date)
        const d1 = dispatch(fetchAuthData(store.getState().authData.auth))
        Promise.resolve(d1).then(() => {
            const d2 = dispatch(fetchEvaluationData({date: str, auth: store.getState().authData.auth}))
            const d3 = dispatch(fetchSensorGraphData({date: str, auth: store.getState().authData.auth}));
            Promise.resolve(d2).then((data: any) => {
                setEvaluationData(data.payload.evaluations)
                changeData(data.payload.evaluations);
            })
            Promise.resolve(d3).then((data: any) => {
                setSensorGraphData(data.payload.sensorGraph)
                changeGraph(data.payload.sensorGraph);
            })
        })
   }

    const nextDate = () => {
        newDate = date;
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
        const str: string = newDate.getFullYear() + "-" + ('0' + (newDate.getMonth() + 1)).slice(-2) + "-" + ('0' + (newDate.getDate())).slice(-2)
        setDateString(str);
        displayNextDayButton(date)
        const d1 = dispatch(fetchAuthData(store.getState().authData.auth))
        Promise.resolve(d1).then(() => {
            const d2 = dispatch(fetchEvaluationData({date: str, auth: store.getState().authData.auth}))
            const d3 = dispatch(fetchSensorGraphData({date: str, auth: store.getState().authData.auth}));
            Promise.resolve(d2).then((data: any) => {
                setEvaluationData(data.payload.evaluations)
                changeData(data.payload.evaluations);
            })
            Promise.resolve(d3).then((data: any) => {
                setSensorGraphData(data.payload.sensorGraph)
                changeGraph(data.payload.sensorGraph);
            })
        })
    }

    const changeData = (evaluations: EvaluationData[], sensorNumber: number = currentSensor) => {
        let sensorEvaluation: number[];
        if (evaluations.length > 0) {
            const ev: EvaluationData[] = evaluations;
            if (ev[sensorNumber].available === true) {
                sensorEvaluation = [ev[sensorNumber].good, ev[sensorNumber].normal, ev[sensorNumber].poor]
                evaluationDispData.datasets[0].data = sensorEvaluation;
                evalCountUp();
            }
        }
    }

    const changeGraph = (sensorGraph: SensorGraphData, graphNumber: number = currentGraph, sensorNumber: number = currentSensor) => {
        let sensorGraphData: number[];
        switch (graphNumber) {
        case 0:
        default:
            sensorGraphData = sensorGraph.temp[sensorNumber];
            break;
        case 1:
            sensorGraphData = sensorGraph.hum[sensorNumber];
            break;
        case 2:
            sensorGraphData = sensorGraph.co2[sensorNumber];
            break;
        case 3:
            sensorGraphData = sensorGraph.noise[sensorNumber];
            break;
        case 4:
            sensorGraphData = sensorGraph.light[sensorNumber];
            break;
        }
        detailDummyDataList[graphNumber].datasets[0].data = sensorGraphData;
        detailDummyData = detailDummyDataList[graphNumber];
        detailCountUp();
    }

    const sensorSelectorClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        const btnElements = document.getElementsByClassName("sensor-selector");
        for (let i: number = 0; i < btnElements.length; i++) {
            btnElements[i].classList.remove("selected");
            btnElements[i].ariaChecked = "false";
        }
        event.currentTarget.classList.add("selected");
        event.currentTarget.ariaChecked = "true";
        const label: string = event.currentTarget.innerText;
        const titles = document.getElementsByClassName("sensor-name");
        titles[0].innerHTML = label;

        const sensor: number = Number(event.currentTarget.dataset.number)
        setSensor(sensor);
        setSensorTitle(label);
        changeData(evaluationDataState.evaluations, sensor);
        changeGraph(sensorGraphDataState, currentGraph, sensor);
    }

    const detailSelectorClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        const btnElements = document.getElementsByClassName("detail-selector");
        for (let i: number = 0; i < btnElements.length; i++) {
            btnElements[i].classList.remove("selected");
            btnElements[i].ariaChecked = "false";
        }
        event.currentTarget.classList.add("selected");
        event.currentTarget.ariaChecked = "true";
        const label: string = event.currentTarget.innerText;
        const titles = document.getElementsByClassName("detail-name");
        titles[0].innerHTML = label;

        const btnNumber: number = Number(event.currentTarget.dataset.number);
        setGraph(btnNumber);
        setGraphTitle(label);
        changeGraph(sensorGraphDataState, btnNumber);
    }

    const makeSensorButtons = (names: string[]) => {
        return names.map((name, index) => {
            let cls: string;
            let checked: string;
            if (index === currentSensor) {
                cls = "sensor-selector selected";
                checked = "true";
            } else {
                cls = "sensor-selector";
                checked = "false";
            }
            return (
                <ModalSelectorButton className={cls} role="radio" aria-checked={checked} data-number={index} onClick={sensorSelectorClickHandler}>{name}</ModalSelectorButton>
            )
        })
    }

    const makeGraphButtons = () => {
        const names: string[] = [
            '温度', '湿度', 'CO₂濃度', '音量', '照度',
        ];
        return names.map((name, index) => {
            let cls: string;
            let checked: string;
            if (index === currentGraph) {
                cls = "detail-selector selected";
                checked = "true";
            } else {
                cls = "detail-selector";
                checked = "false";
            }
            return (
                <ModalSelectorButton className={cls} role="radio" aria-checked={checked} data-number={index} onClick={detailSelectorClickHandler}>{name}</ModalSelectorButton>
            )
        })
    }

    useEffect(() => {
        setDate(newDate)
    }, [newDate, counter])

    const dispatch = useDispatch()
    var timer: NodeJS.Timeout

    useEffect(()=>{
        if (isNaN(date.getFullYear())) return;
        const str: string = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + (date.getDate())).slice(-2)
        const d1 = dispatch(fetchAuthData(store.getState().authData.auth))
        Promise.resolve(d1).then(() => {
            const d2 = dispatch(fetchEvaluationData({date: str, auth: store.getState().authData.auth}))
            const d3 = dispatch(fetchSensorGraphData({date: str, auth: store.getState().authData.auth}));
            Promise.resolve(d2).then((data: any) => {
                setEvaluationData(data.payload.evaluations)
                changeData(data.payload.evaluations);
            })
            Promise.resolve(d3).then((data: any) => {
                setSensorGraphData(data.payload.sensorGraph)
                changeGraph(data.payload.sensorGraph);
            })
        })

        return ()=> clearTimeout(timer)
    },[setEvaluationData,evaluationDataState.isDataexist,isOpen])  // eslint-disable-line

    const [fwdClass, setFwdClass] = useState("forward")

    const displayNextDayButton = (date: Date) => {
        const today: Date = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        if (date.getTime() >= today.getTime()) {
            setFwdClass("forward end");
        } else {
            setFwdClass("forward");
        }
    }

    useEffect(() => {
        displayNextDayButton(date)
    }, [date, fwdClass])

    ChartJS.register(ArcElement, LineElement, Legend, CategoryScale, LinearScale, PointElement, LineController, DoughnutController, Title);

    return (
        <ModalGraphWrapper>
            <ModalGraph key={counter}>

                <input id="sel-date" type="hidden" name="sel-date" value={props.dateData} />

                <ModalGraphDateTitle>
                    <NavBackBtnImage onClick={prevDate} />
                    {date.getMonth() + 1}/{date.getDate()}の環境
                    <NavForwardBtnImage className={fwdClass} onClick={nextDate} />
                </ModalGraphDateTitle>

                <ModalGraphOuterContainer>
                <ModalGraphContainer>
                    <ModalGraphInnerContainer>

                        <ModalGraphTitleWrapper>環境評価</ModalGraphTitleWrapper>
                        <ModalTarget>-<span className="sensor-name">{sensorTitle}</span>-</ModalTarget>
                        <ModalGraphFrame key={evalCounter}>
                            <Doughnut data={evaluationDispData} options={evaluationDispOptions} height="240" redraw={false}/>
                        </ModalGraphFrame>
                        <ModalSelector role="radiogroup">
                            {makeSensorButtons(props.sensors)}
                        </ModalSelector>

                    </ModalGraphInnerContainer>

                    <ModalGraphInnerContainer>

                        <ModalGraphTitleWrapper>環境詳細</ModalGraphTitleWrapper>
                        <ModalTarget>-<span className="detail-name">{graphTitle}</span>-</ModalTarget>
                        <ModalGraphFrame key={detailCounter}>
                            <Line data={detailDummyData} options={tempDummyOptions} height="240" redraw={false}/>
                        </ModalGraphFrame>
                        <ModalSelector role="radiogroup">
                            {makeGraphButtons()}
                        </ModalSelector>

                    </ModalGraphInnerContainer>

                    <ModalFooterSpacer />
                </ModalGraphContainer>
                </ModalGraphOuterContainer>

            </ModalGraph>
        </ModalGraphWrapper>
    )
}

export default ModalGraphContent
