import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import { FetchAuthDataSuccess, AuthData } from '../../states/utilState'
import { CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js"
import awsConfiguration from '../../awsConfiguration'
import jwtDecode from 'jwt-decode'

const userPool = new CognitoUserPool({
    UserPoolId: awsConfiguration.UserPoolId,
    ClientId: awsConfiguration.ClientId,
})

const refreshTokenIfNecessary = async (authInfo: AuthData) => {
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser) {
        return new Promise((resolve, reject) => {
            cognitoUser.getSession((err: Error | null, result: CognitoUserSession | null) => {
                if (result) {
                    //console.log("** refreshTokenIfNecessary **")
                    const idToken: string = result.getIdToken().getJwtToken()
                    //console.log("after refresh: idToken: " + idToken)
                    const decoded: any = jwtDecode(idToken)
                    const tenant_id: string = decoded["custom:tenant_id"]
                    const email: string = decoded.email;
                    authInfo = {
                        company: tenant_id,
                        userid: email,
                        accessToken: result.getAccessToken().getJwtToken(),
                        idToken: result.getIdToken().getJwtToken(),
                        refreshToken: result.getRefreshToken().getToken(),
                        apiid: authInfo.apiid,
                    }
                    resolve(authInfo)
                } else {
                    reject(err)
                }
            })
        })
    } else {
        return new Promise((resolve, reject) => {
            resolve(authInfo)
        })
    }
}

const getAPIuid = async (tenant: string, idToken: string | null) => {
    try {
        const url: string = "https://hrr2nhqbf0.execute-api.ap-northeast-1.amazonaws.com/v1/uid/" + tenant;
        const param: Object = {
            headers: {
                Authorization: idToken
            }
        }
        const result = await axios.get(url, param)
        return String(result.data.userId)
    } catch (err) {
        console.log(err)
        return null
    }
}

export const fetchAuthData = createAsyncThunk(
    'authData/fetchAuthData',
    async (authInfo: AuthData) => {
        try {
            const result: any = await refreshTokenIfNecessary(authInfo)
            let uid: string | null = authInfo.apiid
            if (!uid) {
                uid = await getAPIuid(result.company, result.idToken)
            }
            const ret: FetchAuthDataSuccess = {
                company: result.company,
                userid: result.userid,
                idToken: result.idToken,
                accessToken: result.accessToken,
                refreshToken: result.refreshToken,
                apiid: uid,
            }
            return ret;
        } catch (err) {
            console.log(err)
        }
    }
)