import React, { useState, useContext } from 'react'
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails
} from "amazon-cognito-identity-js"
import awsConfiguration from '../../../awsConfiguration'
import { useNavigate } from "react-router-dom"
import {
    Wrapper, HeaderWrapper, LogoAreaWrapper, LoginTitleWrapper
} from './styledComponents'
import LoginFormBlock from '../../Organisms/LoginFormBlock'
import ChangePasswordFormBlock from '../../Organisms/ChangePasswordFormBlock'
import { LoggedInContext } from "../../../context/AuthContextProvider";
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setAuth } from "../../../reducer/authData/slice"

const LoginTemplate: React.FC = () => {
    const [isFirstLogin, setFirstLogin] = useState<boolean>(false);
    const [company, setCompany] = useState<string>('')  // eslint-disable-line
    const [email, setEmail] = useState<string>('')  // eslint-disable-line
    const [password, setPassword] = useState<string>('')  // eslint-disable-line
    const [userAttr, setUserAttr] = useState({})
    const [cogUser, setCognitoUser] = useState<CognitoUser>()
    const [loggedIn, setLoggedIn] = useContext(LoggedInContext);    // eslint-disable-line
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const userPool = new CognitoUserPool({
        UserPoolId: awsConfiguration.UserPoolId,
        ClientId: awsConfiguration.ClientId,
    })

    const getAPIuid = async (tenant: string) => {
        try {
            const url: string = "https://hrr2nhqbf0.execute-api.ap-northeast-1.amazonaws.com/v1/uid/" + tenant;
            const result = await axios.get(url)
            return String(result.data.userId)
        } catch (err) {
            console.log(err)
            return null
        }
    }

    const handleLogin = async (company: string, email: string, password: string) => {
        const authenticationDetails = new AuthenticationDetails({
            Username : email,
            Password : password
        })
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        })
        console.log("** handleLogin **")
        setCompany(company)
        setEmail(email)
        setPassword(password)
        const apiid : string | null = await getAPIuid(company)
        if (apiid === null) {
            alert('NG, Login please check email, password');
        }

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                const idToken = result.getIdToken().getJwtToken()
                const accessToken = result.getAccessToken().getJwtToken()
                const refreshToken = result.getRefreshToken().getToken()
                const decodedItk: any = jwtDecode(idToken)
                const tenant_id = decodedItk["custom:tenant_id"]
                if (apiid === tenant_id) {
                    dispatch(setAuth({company: company, userid: email, accessToken: accessToken, idToken: idToken, refreshToken: refreshToken, apiid: apiid}))
                    setLoggedIn({isLogin: true});
                    setEmail('')
                    setPassword('')
                    console.log("OK, signIn");
                    navigate("/");
                } else {
                    alert('NG, Login please check email, password');
                }
            },
            onFailure: (err) => {
                alert('NG, Login please check email, password');
            },
            newPasswordRequired: (userAttributes) => {
                dispatch(setAuth({company: company, userid: email, accessToken: null, idToken: null, refreshToken: null, apiid: null}));
                setUserAttr(userAttributes);
                console.log(userAttributes);
                delete userAttributes.email_verified;
                delete userAttributes.email;
                delete userAttributes.phone_number;
                setCognitoUser(cognitoUser);
                setFirstLogin(true);
            }
        })
        
    }

    const changePassword = (password: string, newPassword: string, confirmPassword: string) => {
        const cognitoUser: CognitoUser | undefined = cogUser
        if (newPassword !== confirmPassword) {
            alert('NG, new password does not match');
            return;
        }
        cognitoUser?.completeNewPasswordChallenge(newPassword, userAttr, {
            onSuccess: async (result) => {
                setFirstLogin(false);
                setLoggedIn({isLogin: true});
                const idToken = result.getIdToken().getJwtToken()
                const accessToken = result.getAccessToken().getJwtToken()
                const refreshToken = result.getRefreshToken().getToken()
                const apiid = await getAPIuid(company)
                dispatch(setAuth({company: company, userid: email, accessToken: accessToken, idToken: idToken, refreshToken: refreshToken, apiid: apiid}));
                setEmail('')
                setPassword('')
                navigate("/");
            },
            onFailure: (err) => {
                alert('NG, Login please check email, password');
            }
        });
    };
    return (
        <Wrapper >
            <HeaderWrapper>
                <LogoAreaWrapper></LogoAreaWrapper>
                <LoginTitleWrapper>環境センサー</LoginTitleWrapper>
            </HeaderWrapper>

            {isFirstLogin ? 
                <ChangePasswordFormBlock changePassword={changePassword} />
            :
                <LoginFormBlock handleLogin={handleLogin}/>
            }
        </Wrapper>
    )

}

export default LoginTemplate