import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import {SensorGraphDataState, SensorGraphData, FetchSensorGraphDataSuccess} from '../../states/utilState'

export const fetchSensorGraphData = createAsyncThunk(
    'sensorGraphData/fetchSensorGraphData',
    async (prop: any) => {
        const url: string = "https://hrr2nhqbf0.execute-api.ap-northeast-1.amazonaws.com/v1/graph/" + prop.auth.apiid + "/" + prop.date
        try{
            const param: Object = {
                headers: {
                    Authorization: prop.auth.idToken
                }
            }
            const result: FetchSensorGraphDataSuccess = await (await axios.get<FetchSensorGraphDataSuccess>(url, param)).data
            const sensorGraph: SensorGraphData = {
                    temp: result.temp,
                    hum: result.hum,
                    co2: result.co2,
                    light: result.light,
                    noise: result.noise,
            }
            const ret: SensorGraphDataState = {
                sensorGraph: sensorGraph,
                isDataexist: true,
            }
            return ret;
        } catch(err){
            console.log(err)
        }
    }
)