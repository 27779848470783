import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form';
import { LoginFormBlockWrapper, LoginFormWrapper } from "./styledComponents"

type Inputs = {
    password: string;
    newPassword: string;
    confirmPassword: string;
};

const ChangePasswordFormBlock:React.FC<{changePassword:(password: string, newPassword: string, confirmPassword: string) => void}> = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
       props.changePassword(data.password, data.newPassword, data.confirmPassword)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LoginFormBlockWrapper>

                <LoginFormWrapper>
                    変更前パスワード
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="password" {...register('password', { required: true })} />
                </LoginFormWrapper>
                {errors.password && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}

                <LoginFormWrapper>
                    変更後パスワード
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="password" {...register('newPassword', { required: true })} />
                </LoginFormWrapper>
                {errors.newPassword && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}

                <LoginFormWrapper>
                    変更後パスワード（確認用）
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="password" {...register('confirmPassword', { required: true })} />
                </LoginFormWrapper>
                {errors.confirmPassword && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}

                <LoginFormWrapper>
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="submit" value="変更" />
                </LoginFormWrapper>

            </LoginFormBlockWrapper>
        </form>
    )
}

export default ChangePasswordFormBlock

