import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import { FetchOfficeDataSuccess, AuthData } from '../../states/utilState'

export const fetchOfficeData = createAsyncThunk(
    'officeData/fetchOfficeData',
    async (authInfo: AuthData) => {
        const url: string = "https://hrr2nhqbf0.execute-api.ap-northeast-1.amazonaws.com/v1/latest-data/" + authInfo.apiid
        try{
            const param: Object = {
                headers: {
                    Authorization: authInfo.idToken
                }
            }
            return await (await axios.get<FetchOfficeDataSuccess>(url, param)).data
        } catch(err){
            console.log(err)
        }
    }
)