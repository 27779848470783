import { ModalWrapper, ModalTab, ModalHeader, FooterWrapper, FooterNavWrapper, FooterMenuWrapper } from "./styledComponents"
import { NavBackBtnImage, NavForwardBtnImage, MenuBtnImage} from "../../../style/image"
import ModalMenuContent from "../../Molecules/ModalMenuContent"
import { CloseBtnImage } from "../../../style/image"
import React, { useState } from 'react'

const ModalBlock:React.FC = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isInit, setIsInit] = useState(true);
    const [isImmediate, setIsImmediate] = useState(false);
    const [modalScroll, setModalScroll] = useState(0); //モーダルを開いた時のスクロール値を取っておく

    const toggle = () => {
        setIsInit(false);
        if (!isOpen) {
            setModalScroll(window.scrollY);
            let body = document.getElementsByTagName("body");
            body[0].style.top = -window.scrollY + "px";
            body[0].classList.add("modal-opened");
        } else {
            let body = document.getElementsByTagName("body");
            body[0].style.top = "0px";
            body[0].classList.remove("modal-opened");
            window.scrollTo(0, modalScroll);
            setModalScroll(0);
        }
        setIsOpen(!isOpen);
    }
    const hide = () => {
        setIsInit(false);
        let body = document.getElementsByTagName("body");
        body[0].style.top = "0px";
        body[0].classList.remove("modal-opened");
        window.scrollTo(0, modalScroll);
        setModalScroll(0);
        setIsOpen(false);
    }

    const closeImmediately = () => {
        setIsImmediate(true);
        let body = document.getElementsByTagName("body");
        body[0].style.top = "0px";
        body[0].classList.remove("modal-opened");
        window.scrollTo(0, modalScroll);
        setModalScroll(0);
        setIsOpen(false);
    }

    const dummyHandler = () => {
        return;
    }

    return (
        <div>
            <ModalWrapper className={`bkgnd-${isOpen ? 'open' : isInit ? 'init' : isImmediate ? 'close-immediately' : 'close'}`}>
                <ModalTab className={`tab-${isOpen ? 'open' : isInit ? 'init' : isImmediate ? 'close-immediately' : 'close'}`}>
                    <ModalHeader>
                        <CloseBtnImage onClick={hide} />
                    </ModalHeader>
                    <ModalMenuContent init={isInit} open={isOpen} closeHandler={closeImmediately}/>
                </ModalTab>
            </ModalWrapper>

            <FooterWrapper id={"sensor-footer"}>
                <FooterNavWrapper />
                <FooterMenuWrapper onClick={toggle}>
                    <MenuBtnImage />
                </FooterMenuWrapper>
            </FooterWrapper>
        </div>
    )
}

export default ModalBlock
