import React, { useEffect, useState } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js"
import awsConfiguration from '../awsConfiguration'

export interface AuthContextInterface {
  isAuth: boolean,
  setState: (value: boolean) => void,
}

export type LoginInfo = {
  isLogin: boolean;
};

export interface SensorListContextInterface {
  sensorNames: string[],
  setState: (value: string[]) => void,
}

export type SensorListInfo = {
  sensorNames: string[];
};

// ログイン状態のContext
//export const LoggedInContext = React.createContext<boolean>(false);
export const LoggedInContext = React.createContext<
  [LoginInfo, React.Dispatch<React.SetStateAction<LoginInfo>>]
>([{ isLogin: false }, () => {}]);

// センサー名リストのContext
export const SensorListContext = React.createContext<
  [SensorListInfo, React.Dispatch<React.SetStateAction<SensorListInfo>>]
>([{ sensorNames: [] }, () => {}]);

const userPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId,
})

export const AuthContextProvider: React.FC<{}> = (props) => {
  const cognitoUser = userPool.getCurrentUser()
  let isLoggedIn: LoginInfo = {isLogin: false}

  if (cognitoUser) {
    isLoggedIn = {isLogin: true}
    console.log("login")
  } else {
    isLoggedIn = {isLogin: false}
    console.log("logout")
  }

  const [loggedIn, setLoggedIn] = useState<LoginInfo>(isLoggedIn);
  const [sensorList, setSensorList] = useState<SensorListInfo>({ sensorNames: [] });

  // authInfoのバリデーション
  useEffect(() => {
    // authInfoに正しく値がセットされているかどうかをチェック
    if (!loggedIn?.isLogin) {
      setLoggedIn({isLogin: true});
    }
  }, [loggedIn]);

  return (
    <LoggedInContext.Provider value={[loggedIn, setLoggedIn]}>
      <SensorListContext.Provider value={[sensorList, setSensorList]}>
        {props.children}
      </SensorListContext.Provider>
    </LoggedInContext.Provider>
  );
};
