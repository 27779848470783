import React, { useContext } from 'react'
import { Navigate } from "react-router-dom"
import MainTemplate from '../Template/MainTemplate/MainTemplate'
import { LoggedInContext, LoginInfo, SensorListInfo } from "../../context/AuthContextProvider";

const Main: React.FC<{login: LoginInfo, sensors: SensorListInfo}> = (props) => {
    const [isLoggedIn] = useContext(LoggedInContext);

    if (!isLoggedIn.isLogin) {
        return (
            <Navigate to="/login" replace></Navigate>  
        )
    } else {
        return (
            <MainTemplate sensors={props.sensors}>
            </MainTemplate>
        )
    }
}

export default Main