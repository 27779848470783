import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import { FetchMinMaxTempDataSuccess, AuthData } from '../../states/utilState'

export const fetchMinMaxTempData = createAsyncThunk(
    'minMaxData/fetchMinMaxTempData',
    async (authInfo: AuthData) => {
        const url: string = "https://hrr2nhqbf0.execute-api.ap-northeast-1.amazonaws.com/v1/minmax/" + authInfo.apiid
        try{
            const param: Object = {
                headers: {
                    Authorization: authInfo.idToken
                }
            }
            return await (await axios.get<FetchMinMaxTempDataSuccess>(url, param)).data
        } catch(err){
            console.log(err)
        }
    }
)