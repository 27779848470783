import React, { useContext } from 'react'
import { Navigate } from "react-router-dom"
import DetailTemplate from '../Template/DetailTemplate/DetailTemplate'
import { LoggedInContext, LoginInfo, SensorListInfo } from "../../context/AuthContextProvider";

const Detail: React.FC<{date: Date, login: LoginInfo, sensors: SensorListInfo}> = (props) => {
    const [isLoggedIn] = useContext(LoggedInContext);

    if (!isLoggedIn.isLogin) {
        return (
            <Navigate to="/login" replace={true}></Navigate>  
        )
    }
    return (
        <DetailTemplate initialDate={props.date} key={props.date.getMonth() + 1} sensors={props.sensors}>
        </DetailTemplate>
    )
}

export default Detail