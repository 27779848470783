import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Wrapper, HeaderWrapper, LogoAreaWrapper, WeatherWrapper, EnvDateWrapper, EnvTitleWrapper, TabContainer, Tab } from './styledComponents'
import DateSpanBlock from '../../Organisms/DateSpanBlock'
import CalendarContainer from '../../Organisms/CalendarBlock'
import ModalGraphBlock from '../../Organisms/ModalGraphBlock'
import ModalBlock from '../../Organisms/ModalBlock'
import { SensorListInfo } from '../../../context/AuthContextProvider'
import { FetchMinMaxTempData } from '../../../states/utilState'
import { useDispatch } from 'react-redux'
import { useMinMaxTempDataState } from '../../../reducer/minMaxData/selectors'
import { fetchMinMaxTempData } from '../../../reducer/minMaxData/asyncAction'
import { fetchAuthData } from "../../../reducer/authData/asyncAction"
import store from "../../../stores"

const DetailTemplate: React.FC<{initialDate: Date, sensors: SensorListInfo}> = (props) => {
    const [date, setDate] = useState(props.initialDate)
    const [firstDate, setFirstDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1))
    const [lastDate, setLastDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    const [isOpen, setIsOpen] = useState(false);
    const [clickedDate, setDateString] = useState("");
    const [counter, setCount] = useState(100000);
    const [modalScroll, setModalScroll] = useState(0); // eslint-disable-line
    const [resizeFired, setResize] = useState(true);
    const month: number = date.getMonth() + 1;

    const dateClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.currentTarget.dataset.date !== undefined) {
            setDateString(event.currentTarget.dataset.date);
        }
        countUp();
    }

    const prevMonthHandler = (event: React.MouseEvent<HTMLImageElement>) => {
        setDate(new Date(date.getFullYear(), date.getMonth() - 1));
        setFirstDate(new Date(firstDate.getFullYear(), firstDate.getMonth() - 1, 1));        
        setLastDate(new Date(lastDate.getFullYear(), lastDate.getMonth(), 0));       
    }

    const nextMonthHandler = (event: React.MouseEvent<HTMLImageElement>) => {
        setDate(new Date(date.getFullYear(), date.getMonth() + 1));
        setFirstDate(new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 1));        
        setLastDate(new Date(lastDate.getFullYear(), lastDate.getMonth() + 2, 0));        
    }

    const openModal = () => {
        setModalScroll(window.scrollY);
        let body = document.getElementsByTagName("body");
        body[0].style.top = -window.scrollY + "px";
        body[0].classList.add("modal-opened");
        setIsOpen(true);
    }

    const countUp = () => {
        setCount(counter + 1);
    }

    useEffect(() => {
        openModal();
    }, [clickedDate, counter])

    const dispatch = useDispatch()
    var timer: NodeJS.Timeout
    const minMaxDataState = useMinMaxTempDataState().minMaxData
    /* eslint-disable */
    const [minMaxData, setMinMaxData] = useState<FetchMinMaxTempData>()
    /* eslint-enable */
    
    const minMaxPolling = () => {
        timer = setTimeout(()=>{
            const d1 = dispatch(fetchAuthData(store.getState().authData.auth))
            Promise.resolve(d1).then(() => {
                dispatch(fetchMinMaxTempData(store.getState().authData.auth))
            })
            minMaxPolling()
            setMinMaxData(minMaxDataState.temp)
        }, 60 * 60 * 1000)
    }
    useEffect(()=>{
        const d1 = dispatch(fetchAuthData(store.getState().authData.auth))
        Promise.resolve(d1).then(() => {
            dispatch(fetchMinMaxTempData(store.getState().authData.auth))
        })
        minMaxPolling()
        return ()=> clearTimeout(timer)
    },[setMinMaxData,minMaxDataState.isDataexist])  // eslint-disable-line

    const setScrollHeight = () => {
        const innerHeight = window.innerHeight;
        //console.log("innerHeight: " + innerHeight);
        let container: HTMLElement | null = document.getElementById("sensor-scroll");
        let header: HTMLElement | null = document.getElementById("sensor-header");
        let footer: HTMLElement | null = document.getElementById("sensor-footer");
        if (container && header && footer) {
            const headerHeight: number = Number(header.clientHeight);
            const footerHeight: number = Number(footer.clientHeight);
            const frameHeight: number = innerHeight - headerHeight - footerHeight;
            //console.log("headerHeight: " + headerHeight);
            //console.log("footerHeight: " + footerHeight);
            //console.log("frameheight: " + frameHeight);

            container.style.height = frameHeight + "px";
            container.style.top = headerHeight + "px";
            setResize(false);
        }
    }

    window.addEventListener('resize', () => {
        setTimeout(() => {
            setResize(true);
        }, 150);
    })
    window.addEventListener('orientationchanged', () => {
        setTimeout(() => {
            setResize(true);
        }, 150);
    })

    useEffect(() => {
        setScrollHeight();
    }, [resizeFired, firstDate])

    return (
        <Wrapper >
            <HeaderWrapper id={"sensor-header"}>
                <LogoAreaWrapper></LogoAreaWrapper>
                <WeatherWrapper>{minMaxDataState.temp?.addr}　{minMaxDataState.temp?.max}℃/{minMaxDataState.temp?.min}℃</WeatherWrapper>
                <TabContainer>
                    <Link to="/"><Tab className="left">現在の環境</Tab></Link>
                    <Tab className="right active">環境評価振り返り</Tab>
                </TabContainer>
                <EnvDateWrapper>
                    <EnvTitleWrapper>{month}月の環境</EnvTitleWrapper>
                    <DateSpanBlock start={firstDate} end={lastDate} />
                </EnvDateWrapper>
            </HeaderWrapper>

            <CalendarContainer date={date} key={date.getMonth() + 1} onDateClick={dateClickHandler} onPrevMonthClick={prevMonthHandler} onNextMonthClick={nextMonthHandler} />

            <ModalGraphBlock dateData={clickedDate} open={isOpen} sensors={props.sensors.sensorNames} key={counter} />
            <ModalBlock />

        </Wrapper>
    )
}

export default DetailTemplate