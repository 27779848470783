import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form';
import { LoginFormBlockWrapper, LoginFormWrapper } from "./styledComponents"

type Inputs = {
    company: string;
    userid: string;
    password: string;
};

const LoginFormBlock:React.FC<{handleLogin:(company: string, email:string, password:string) => void}> = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
       props.handleLogin(data.company, data.userid, data.password)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LoginFormBlockWrapper>

                <LoginFormWrapper>
                    会社コード
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="text" {...register('company', { required: true })} />
                </LoginFormWrapper>
                {errors.company && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}
                <LoginFormWrapper>
                    ID
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="text" {...register('userid', { required: true })} />
                </LoginFormWrapper>
                {errors.userid && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}

                <LoginFormWrapper>
                    パスワード
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="password" {...register('password', { required: true })} />
                </LoginFormWrapper>
                {errors.password && (
                    <span style={{ color: "red" }}>入力必須項目です。</span>
                )}

                <LoginFormWrapper>
                </LoginFormWrapper>

                <LoginFormWrapper>
                    <input type="submit" value="ログイン" />
                </LoginFormWrapper>

            </LoginFormBlockWrapper>
        </form>
    )
}

export default LoginFormBlock

