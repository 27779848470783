import {
    ModalMenuWrapper, ModalMenu, ModalMenuItem, ModalMenuIcon, ModalMenuText
} from "./styledComponents"
import { useNavigate, useLocation } from 'react-router-dom'
import { TimeIconImage, MonthIconImage, TodayIconImage, LogoutIconImage } from "../../../style/image"
import React, { useContext } from 'react'
import { LoggedInContext } from "../../../context/AuthContextProvider";
import { CognitoUserPool } from "amazon-cognito-identity-js"
import awsConfiguration from '../../../awsConfiguration'
import { resetAuth } from '../../../reducer/authData/slice'
import { useDispatch } from 'react-redux'

const ModalMenuContent:React.FC<{init: boolean, open: boolean, closeHandler:()=>void}> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    /* eslint-disable */
    const [isLoggedIn, setLoggedIn] = useContext(LoggedInContext);
    /* eslint-enable */

    const menuNavigation = (path: string) => {
        if (!props.init && props.open && location.pathname === path) {
            props.closeHandler();
        } else if (!props.init && location.pathname !== path) {
            navigate(path);
        }
    }

    const logout = () => {
        const userPool: CognitoUserPool = new CognitoUserPool({
            UserPoolId: awsConfiguration.UserPoolId,
            ClientId: awsConfiguration.ClientId,
        })
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            // ログアウト
            cognitoUser.signOut();
            dispatch(resetAuth({company: "", userid: "", accessToken: null, idToken: null, refreshToken: null, apiid: null}))
            setLoggedIn({isLogin: false});
            navigate("/login");
        }
    }

    return (
        <ModalMenuWrapper>
            <ModalMenu>

                <ModalMenuItem onClick={() => menuNavigation("/")}>
                    <ModalMenuIcon><TimeIconImage /></ModalMenuIcon>
                    <ModalMenuText>現在の環境</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem onClick={() => menuNavigation("/detail")}>
                    <ModalMenuIcon><MonthIconImage /></ModalMenuIcon>
                    <ModalMenuText>月ごとの環境（環境評価-月）</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem onClick={() => menuNavigation("/detail")}>
                    <ModalMenuIcon><TodayIconImage /></ModalMenuIcon>
                    <ModalMenuText>日ごとの環境（環境評価-日）</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem onClick={logout}>
                    <ModalMenuIcon><LogoutIconImage /></ModalMenuIcon>
                    <ModalMenuText>ログアウト</ModalMenuText>
                </ModalMenuItem>

                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />
                <ModalMenuItem />

            </ModalMenu>
        </ModalMenuWrapper>
    )
}

export default ModalMenuContent
